import { getDatePickerDate, getPreviousMonthDate } from "../Helper/DatePicker/DateConverter";
import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";

const initialStartDate = {
  value: decryptTheParams()?.startDate || getPreviousMonthDate()
}
const initialEndDate = {
  value: decryptTheParams()?.endDate || getDatePickerDate()
}

const ChargingAnalyticsStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "ChargingAnalyticsStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const ChargingAnalyticsEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "ChargingAnalyticsEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};

export {
  ChargingAnalyticsStartDate,
  ChargingAnalyticsEndDate
}