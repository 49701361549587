import React from "react"
import PageHeaderSection from "../../PagesContent/ChargingAnalytics/PageHeaderSection"
import { InnerBox, OuterBox } from "../../Components/CustomBox"
import KPISection from "../../PagesContent/ChargingAnalytics/KPISection"
import ChartsSection from "../../PagesContent/ChargingAnalytics/ChartsSection"

function ChargingAnalyticsMain({ pagesContent }) {
  return (
    <OuterBox>
      <InnerBox>
        <PageHeaderSection pagesContent={pagesContent}/>
        <KPISection pagesContent={pagesContent}/>
        <ChartsSection pagesContent={pagesContent}/>
      </InnerBox>
    </OuterBox>
  )
}

export default ChargingAnalyticsMain
