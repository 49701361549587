import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Cookies from "universal-cookie";

const cookies = new Cookies();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(cookies.get("timeZone"));

export const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().tz().add(-6, "d"), dayjs().tz()]
  },
  {
    label: "Last 14 Days",
    value: [dayjs().tz().add(-13, "d"), dayjs().tz()]
  },
  {
    label: "Last 1 Month",
    value: [dayjs().tz().subtract(1, "month"), dayjs().tz()]
  },
  {
    label: "Last 3 Months",
    value: [dayjs().tz().subtract(3, "month"), dayjs().tz()]
  },
  {
    label: "Last 6 Months",
    value: [dayjs().tz().subtract(6, "month"), dayjs().tz()]
  }
]
