import React from "react";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { Typography, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Events from "../../Analytics/Events";

function TextHyperLinkWithCopyIcon(text, type, onTextClick, record) {
  return (
    <>
      <Typography
        variant="tableRow"
        onClick={() => onTextClick(record)}
        sx={{
          ":hover": {
            color: ThemeProperties.blue_400,
            cursor: "pointer",
          },
        }}
      >
        {text}
      </Typography>
      <Tooltip title={"Click to copy"}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            copy(text);
            Events(`Copied ${type}`);
          }}
        >
          <ContentCopyIcon
            sx={{
              fontSize: "13px",
              cursor: "pointer",
              "&:hover": {
                color: ThemeProperties.purple,
              },
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default TextHyperLinkWithCopyIcon;
