import { styled } from "@mui/material/styles"
import { Box, Button, FormControlLabel, Typography } from "@mui/material"
import { CustomText, FlexBox, FlexCenterBox, FullWidthSeparator } from "../../../Components/CustomBox"
import { ThemeProperties } from "../../../Theme/ThemeProperties"
import Launch from "@mui/icons-material/Launch";
import { getMarkerColorForSOHChart } from "../../../Helper/BatteryHealth/BatteryHealthHelper";
import { PaperHighlight } from "../../../Components/CustomPaper";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SOHChartContainer = styled(Box)({
  height: "400px",
  position: "relative",
  padding: "12px"
})

const HealthDistributionChartContainer = styled(Box)({
  height: "420px",
  position: "relative",
})

const EqCycleSwitchBox = styled(FormControlLabel)({
  marginLeft: "-5px",
  marginTop: "-3.2px"
})

const HealthDistributionBatteriesCountBox = styled(FlexCenterBox)({
  position:"absolute",
  top:"133px",
  width:"100%",

  "@media (max-width: 1024px)": {
    top:"113px",
  }
})

const BatteriesCountPostFixBox = styled(FlexCenterBox)({
  position:"absolute",
  top:"160px",
  width:"100%",

  "@media (max-width: 1024px)": {
    top:"140px",
  }
})

const BatteriesCountPostFixText = styled(CustomText)({
  fontWeight: "400",
  fontSize: "14px",
  color: "#444B51"
})

const SOHLevelResetButton = styled(Button)({
  textDecoration: "underline",
  textTransform: "none",
  position: "absolute",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
  padding: 0,
  margin: 0, 
  marginTop: "14px",
  marginRight: "4px",
  zIndex: 2,
})

const SelectedSOHBox = styled(FlexBox)({
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const SOHTableNoDataFoundContainer = styled(FlexCenterBox)({
  width: '100%',
  alignItems: 'center',
  minHeight: "300px",
})

const BatteryInfoGotToSBContainer = styled(FlexCenterBox)({
  alignItems: 'center',
  marginTop: "31px",
  cursor: "pointer",
})

const BatteryInfoSeparator = styled(FullWidthSeparator)({
  borderTopColor: "#ECECEC",
  marginLeft: "50px",
  marginRight: "50px"
})

const BatteryInfoSOHBox = styled(FlexCenterBox)({
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: "12px"
})

const BatteryInfoBox = styled(FlexBox)({
  flexDirection: 'column',
  paddingTop: "5px",
  paddingLeft: "60px",
  paddingRight: "50px",

  "@media (max-width: 1024px)": {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
})

const BatteryInfoItem = styled(FlexBox)({
  paddingBottom: "6px"
})

const BatteryInfoGroupHeading = styled(Typography)({
  fontFamily: 'Inter',
  fontWeight: "700",
  fontSize: "10px",
  lineHeight: "13px",
  color: "#444B51",
  marginLeft: "60px",
  marginTop: "10px"
})

const BatteryInfoLabel = styled(Typography)({
  width: "140px",
  fontFamily: 'Inter',
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  whiteSpace: 'nowrap',
  color: "#444B51"
})

const BatteryInfoValue = styled(CustomText)({
  fontFamily: 'Roboto',
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  whiteSpace: 'nowrap',
  color: "#1C1C1C"
})

const BatteryInfoContainer = styled(Box)({
  padding: '12px',
  paddingTop: "40px",
  height: "400px", 
})

const BatteryCountText = styled(CustomText)({
  fontWeight: 700,
  fontSize: "24px",
  color: "#1C1C1C"
})

const BatteryInfoSOHValueText = styled(CustomText)(({ soh }) => {
  return ({
  fontWeight: 400,
  fontSize: "40px",
  color:
    Number.isFinite(soh)
      ? getMarkerColorForSOHChart(soh)
      : "#000",
  lineHeight: "46px"
})
})

const SOHTableNoDataFoundText = styled(CustomText)({
  color: "#909090", 
  fontSize: "16px",
  fontWeight:600
})

const LaunchIcon = styled(Launch)({
  color: ThemeProperties.purple,
  marginLeft: "4px",
})

const BatteryInfoPaperHighlight = styled(PaperHighlight)({
  height: "480px"
})

const ShowEqCycleText = styled(CustomText)({
  fontWeight: "400",
  fontSize: "12px",
  marginLeft: "2px"
})

const EqCycleInfoIcon = styled(InfoOutlinedIcon)({
  color: ThemeProperties.purple,
  marginLeft: "-10px",
  marginTop: "-2px"
})

const GoToBatteryPageText = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#4D44D8"
})

const DegradationChartLegendLine = styled(Box)(({ width, color, borderStyle }) => ({
  borderTopWidth: width || "2px",
  borderTopStyle: borderStyle || "dashed",
  borderTopColor: color || "black",
  width: "20px"
}))

const DegradationChartLegendBox = styled(Box)(({ width, color, borderStyle }) => ({
  borderRadius: "2px",
  height: "7px",
  width: "20px",
  backgroundColor: color || 'transparent'
}))

const DegradationChartLegendLabel = styled(CustomText)({
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: '16px',
  color: "#444B51",
  marginLeft: "4px"
})

const DegradationChartLegendContainer = styled(FlexBox)({
  alignItems: "center",
  marginLeft: "35px",
})

const DegradationChartContainer = styled(Box)({
  marginTop: "15px",
  height: "380px",
  position: "relative"
})

const DegradationChartNoDataContainer = styled(FlexCenterBox)({
  position: 'absolute',
  left: '1%',
  top: '40%',
  zIndex: 9,
  width: '100%',
})

const DegradationChartNoDataTextBox = styled(Box)({
  backgroundColor: '#ececec',
  padding: '10px',
  borderRadius: '20px',
})

const DegradationChartNoDataText = styled(Typography)({
});

const EqCycleInfoTooltip = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
  textAlign: "left",
  maxWidth: "285px",
})

export { 
  EqCycleInfoIcon,
  EqCycleInfoTooltip,
  LaunchIcon,
  ShowEqCycleText,
  BatteryCountText,
  SelectedSOHBox,
  BatteryInfoSeparator,
  BatteryInfoBox,
  BatteryInfoItem,
  BatteryInfoLabel,
  BatteryInfoValue,
  GoToBatteryPageText,
  DegradationChartNoDataText,
  DegradationChartNoDataTextBox,
  DegradationChartNoDataContainer,
  DegradationChartLegendLine,
  DegradationChartLegendLabel,
  DegradationChartLegendBox,
  DegradationChartLegendContainer,
  BatteryInfoGroupHeading,
  BatteryInfoSOHBox,
  SOHChartContainer,
  BatteryInfoContainer,
  EqCycleSwitchBox,
  BatteriesCountPostFixText,
  BatteriesCountPostFixBox,
  SOHLevelResetButton,
  BatteryInfoGotToSBContainer,
  BatteryInfoSOHValueText,
  DegradationChartContainer,
  HealthDistributionChartContainer,
  SOHTableNoDataFoundContainer,
  SOHTableNoDataFoundText,
  BatteryInfoPaperHighlight,
  HealthDistributionBatteriesCountBox
}
