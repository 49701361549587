import Chart from "react-apexcharts";
import CODE from "../../Static/Constants/StatusCodes";
import { DigitalTwinChartLoader } from "../../Components/CustomBox";
import { Box, Typography } from "@mui/material";
import PulseLoader from "react-spinners/MoonLoader";
import { HeatMapLegendContainer, HeatMapLegendGradientBox, HeatMapLegendText } from "../../PagesContent/ChargingAnalytics/Components/StyledComponent";

const AnalyticsBasicTreeMap = ({state, series, rangesSetting, tooltipYAxisName, chartLegendSettings, yAxisSetting}) => {
  const isEmpty = ![CODE.LOADING, CODE.SUCCESS].includes(state)
  const options = {
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
            type: 'none',
            value: 0,
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
            type: 'none',
            value: 0,
        }
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        colorScale: {
          ranges: rangesSetting
        }
      }
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#E9EFF5",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      axisBorder: {
        show: true,
        color: '#87939D',
        offsetX: 0,
        offsetY: 1
      },
      axisTicks: {
        show: true,
        color: '#78909C',
        height: 4,
        offsetX: 0,
        offsetY: 0
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
            colors: '#87939D',
            fontSize: '12px',
            fontFamily: 'Inter, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        offsetY: -3,
      }
    },
    tooltip: {
      enabled: !isEmpty,
      y: {
        formatter: undefined,
        title: {
            formatter: (seriesName) => tooltipYAxisName || seriesName,
        },
      },
    }
  }

  
  const ChartComponent = () => {
    return <>
      <HeatMapLegendContainer>
        <HeatMapLegendText>{chartLegendSettings.min}</HeatMapLegendText>
        <HeatMapLegendGradientBox gradientStyle={chartLegendSettings.gradientStyle} />
        <HeatMapLegendText>{chartLegendSettings.max}</HeatMapLegendText>
      </HeatMapLegendContainer>
      <Chart
        options={{
          ...options,  
            yaxis: yAxisSetting
        }}
        series={series}
        type="heatmap"
        height="339px"
        width="100%"
      />
    </>
  }

  const Loader = () => {
    return <DigitalTwinChartLoader>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <PulseLoader
          color={"#B2AFC3"}
          size={30}
          speedMultiplier={1}
        />
      </Box>
      <Typography
        style={{
          color: "#B2AFC3",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        Loading
      </Typography>
    </DigitalTwinChartLoader>
  }

  const NoDataMessage = () => {
    return <div
        style={{
          position: "absolute",
          top: "40%",
          left: "40%",
          zIndex: 2
        }}
      >
        <div
          style={{
            backgroundColor: "#ececec",
            padding: "10px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="textSubtitle">
            No Data Found
          </Typography>
        </div>
      </div>
  }

  return (
    <>
      {state === CODE.LOADING ? <Loader/> : <ChartComponent/>}
      {isEmpty && <NoDataMessage/>}
    </>
  ) 
}

export default AnalyticsBasicTreeMap