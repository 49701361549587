import { styled } from "@mui/material/styles";
import { Toolbar } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const CustomToolbar = styled(Toolbar)({
    background: "linear-gradient(89.57deg, #FFFFFF -6.67%, #EEEBFF 40.47%)",
    zIndex: "9998",
    height:"48px",
    position: "fixed",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
});

export { CustomToolbar };
