import { getSelectAllBatteries, getTableBatteries } from "../../Api/DashboardApi"
import GenericTable from "../../Features/Table/GenericTable"
import { useSelector, useDispatch } from "react-redux"
import CODE from "../../Static/Constants/StatusCodes"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt"
import { SetSessionExpired } from "../../Actions"
import { getDatePickerDate } from "../../Helper/DatePicker/DateConverter"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SetDashboardStack } from "../../Actions/Dashboard"
import Events from "../../Analytics/Events"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Tooltip } from "antd"
import NoData from "../../Helper/Loaders/NoData"
import { FullHeightBox } from "../../Components/CustomBox"

const AlertTableSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allColumns = useSelector((state) => state.TableColumns.value)
  const pageNumber = useSelector((state) => state.TablePageNumber.value)
  const rowsPerPage = useSelector((state) => state.TableRowsPerPage.value)
  const totalRecords = useSelector((state) => state.DashboardTotal.value);
  const tabValue = useSelector((state) => state.DashboardTabValue.value);
  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const sortBy = useSelector((state) => state.TableSortColumn.value) || "openedAt";
  const sortOrder = useSelector((state) => state.TableSortOrder.value);
  const category = useSelector((state) => state.DashboardCategory.value);
  const searchType = useSelector((state) => state.SelectedSearchType.value);
  const searchID = useSelector((state) => state.SelectedSearchID.value);
  const alertCode = useSelector((state) => state.DashboardAlertTypeCode.value);
  const searchConfig = useSelector((state) => state.SelectedSearchConfig.value);
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const existingBatteries = useSelector((state) => state.DashboardExistingBatteries.arr);
  const alertTypeIndexRedux = useSelector((state) => state.DashboardAlertTypeIndex.value);
  const calendarEndDate = useSelector((state) => state.DashboardEndDate.value) || getDatePickerDate();
  const calendarStartDate = useSelector((state) => state.DashboardStartDate.value) || getDatePickerDate(undefined, -45);
  const [columns, setColumns] = useState([])
  const [batteriesAlertData, setBatteriesAlertData] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });
  const selectedRowKeys = []
  const universalStack = useSelector((state) => state.DashboardStack.arr);

  const getListOfBatteries = async () => {    
    if (alertCode === "" || (selectedFilters && Object.keys(selectedFilters).length < 1)) {
      setBatteriesAlertData({
        data: [],
        responseStatus: { code: CODE.NODATA, msg: "" },
      });
      return
    }
    setBatteriesAlertData({
      data: [],
      responseStatus: { code: CODE.LOADING, msg: "" },
    });

    const res = await getTableBatteries(
      alertTypeIndexRedux,
      tabValue,
      alertCode,
      category,
      searchID,
      searchConfig?.keys?.[searchType],
      calendarStartDate,
      calendarEndDate,
      pageNumber,
      sortBy,
      sortOrder,
      rowsPerPage,
      "",
      selectedFilters,
      defaultFilterValues
    )

    if (res?.responseStatus?.code === CODE.SUCCESS) {
      const updatedData = res.response.data.map(item => {
        const id = `${item.batteryID}-${item.alertStatus}-${alertTypeIndexRedux}`.toLowerCase().replace(/ /g, "");
        return { ...item, id };
      });

      setBatteriesAlertData({
        data: updatedData,
        responseStatus: {
          code: res.responseStatus.code,
          msg: res.responseStatus.msg,
        },
      });

      if (searchID !== "") {
        const newParams = {
          batteryID: res.response.data[0]["batteryID"],
          type: "battery",
        };
        updateQueryParams(navigate, newParams);
      }
    } else {
      if (res?.responseStatus?.code === CODE.SESSION_EXPIRED)
        dispatch(SetSessionExpired(true));
      setBatteriesAlertData({
        data: [],
        responseStatus: {
          code: res?.responseStatus?.code,
          msg: res?.responseStatus?.msg,
        },
      });
    }
  }

  const findColumnsForTab = () => {
    const tabName =
      tabValue === 0
        ? "Active"
        : tabValue === 1
          ? "Open"
          : tabValue === 2
            ? "Resolved"
            : tabValue === 3
              ? "Expired"
              : "Ignored";

    const newColumnConfig = allColumns.filter(columnConfig => columnConfig?.show?.includes(tabName))
    setColumns(newColumnConfig)
  }

  const getCheckboxProps = (record) => {
    const idsInStack = existingBatteries.map((item) => {
      return `${item.batteryID}-${item.alertStatus}-${alertTypeIndexRedux}`.toLowerCase().replace(/ /g, "")
    })
    return {
      disabled: idsInStack.includes(record.id),
    }
  }

  const onRowSelect = (selected, selectedRows, changeRows) => {
    let itemIds = universalStack.find(
      (item) => item.alertCode === alertCode
    )["itemIDs"];

    let index = universalStack
      .map((item) => item.alertCode)
      .indexOf(alertCode);
    let newState = [...universalStack];

    if (selectedRows) {
      let stackData = [...itemIds, {
        "batteryID": selected.batteryID,
        "configBOPID": selected.configBOPID,
        "alertMetaID": alertTypeIndexRedux,
        "alertStatus": selected.alertStatus,
        "alertStateIds": selected.alertStateIds
      }];
      newState[index]["itemIDs"] = [...stackData];
      dispatch(SetDashboardStack(newState));
    } else {
      let stackData = itemIds.filter((key) => `${key.batteryID}-${key.alertStatus}-${key.alertMetaID}`.toLowerCase().replace(/ /g, "") !== selected.id);
      newState[index]["itemIDs"] = [...stackData];
      dispatch(SetDashboardStack(newState));
    }
  }

  const onClickSelectAll = async (selected, selectedRows, changeRows) => {
    let newtempIds = [];
    if (selected) {
      if (selectedRows.length !== changeRows.length) {
        return
      }
      const res = await getSelectAllBatteries(
        alertTypeIndexRedux,
        selectedFilters?.location,
        "",
        "",
        category,
        alertCode,
        selectedFilters?.batteryStatus,
        selectedFilters?.severityName,
        selectedFilters?.iotOEM,
        selectedFilters?.packOEM,
        tabValue,
        "",
        "",
        [calendarStartDate, calendarEndDate],

        searchID,
        "",
        searchConfig?.keys?.[searchType],
        defaultFilterValues,
        selectedFilters
      )

      if (res.responseStatus.code === 200) {
        newtempIds = res.response.data;

        let tempSelected = res.response.data;

        let finalArr = tempSelected.filter((n) => !newtempIds.includes(n));
        let final2 = universalStack.find((item) => item.alertCode === alertCode)["itemIDs"].filter((n) => !finalArr.includes(n));
        let final3 = final2.concat(newtempIds);
        let final4 = final3.filter(item1 => {
          return !existingBatteries.some(item2 => item2.batteryID === item1.batteryID && item2.alertMetaID === item1.alertMetaID && item2.alertStatus === item1.alertStatus);
        });

        let uniqueChars = [...new Set(final4)];
        uniqueChars.forEach(item => {
          item.alertStateIds = typeof item.alertStateIds === 'string' ? JSON.parse(item.alertStateIds) : item.alertStateIds;
        });
        let index = universalStack
          .map((item) => item.alertCode)
          .indexOf(alertCode);
        let newState = [...universalStack];
        newState[index]["itemIDs"] = [...uniqueChars];
        props.openNotification(
          "bottomLeft",
          `${uniqueChars.length} alerts selected`
        );
        dispatch(SetDashboardStack(newState));
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        dispatch(SetDashboardStack([]));
      }
    } else {
      let index = universalStack
        .map((item) => item.alertCode)
        .indexOf(alertCode);
      let newState = [...universalStack];
      newState[index]["itemIDs"] = [];
      dispatch(SetDashboardStack(newState));
    }
    Events("clicked global select Alerts page table");
  }

  const renderCell = (checked, record, index, originNode) => {
    return originNode.props.disabled ? (
      <Tooltip
        placement="top"
        title={
          "Battery added to take action. If needed to be removed, please remove from cart"
        }
      >
        <IndeterminateCheckBoxIcon
          sx={{ fontSize: "20px", color: "#d8d8d8", cursor: "no-drop" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Tooltip>
    ) : (
      originNode
    )
  }

  const EmptyTextBox = ({batteriesAlertData}) => {
    return <div style={{minHeight: "500px", height: "500px"}}>
      <FullHeightBox>
        {[CODE.URL_NODATA, CODE.SESSION_EXPIRED, 1999].includes(batteriesAlertData.responseStatus.code) && <NoData text="No Alerts Found" />}
      </FullHeightBox>
    </div>
  }

  useEffect(() => {
    if (defaultFilterValues) {
      getListOfBatteries()
    }
    findColumnsForTab()
  }, [
    allColumns,
    tabValue,
    sortBy,
    sortOrder,
    selectedFilters?.fleetName,
    selectedFilters?.location,
    selectedFilters?.severityName,
    selectedFilters?.batteryStatus,
    selectedFilters?.cellModelNumber,
    selectedFilters?.iotOEM,
    selectedFilters?.pingStatus,
    selectedFilters?.configName,
    alertCode,
    category,
    searchID,
    pageNumber,
    rowsPerPage,
    calendarEndDate,
    calendarStartDate,
    alertTypeIndexRedux,
    timezoneChanged
  ])
  
  useEffect(() => {
    universalStack.map((item) => {
      const IDList = item.itemIDs?.map((itemList) => {
        return `${itemList.batteryID}-${itemList.alertStatus}-${itemList.alertMetaID}`
      }) || []
  
      selectedRowKeys.push(...IDList)
    })
  }, [universalStack])

  universalStack.map((item) => {
    const IDList = item.itemIDs?.map((itemList) => {
      return `${itemList.batteryID}-${itemList.alertStatus}-${itemList.alertMetaID}`
    }) || []

    selectedRowKeys.push(...IDList)
  })

  return <GenericTable 
    key={`alertTable${batteriesAlertData?.data?.length}${columns.length}`}
    selectedRowKeys={selectedRowKeys}
    emptyTextBox={<EmptyTextBox batteriesAlertData={batteriesAlertData}/>}
    tableData={batteriesAlertData}
    columns={columns}
    pageNumber={pageNumber}
    rowsPerPage={rowsPerPage}
    totalRecords={totalRecords}
    isRowSelectable={[0, 1].includes(tabValue)} 
    numberOfFixedColumns={2}
    onClickSelectAll={onClickSelectAll}
    onRowSelect={onRowSelect}
    getCheckboxProps={getCheckboxProps}
    renderCell={renderCell}
    spinning={batteriesAlertData.responseStatus.code === CODE.LOADING}
  />
}

export default AlertTableSection
