import Chart from "react-apexcharts";
import CODE from "../../Static/Constants/StatusCodes";
import { DigitalTwinChartLoader } from "../../Components/CustomBox";
import { Box, Typography } from "@mui/material";
import PulseLoader from "react-spinners/MoonLoader";

const AnalyticsBasicLine = ({state, series, yAxisSetting}) => {
  const isEmpty = ![CODE.LOADING, CODE.SUCCESS].includes(state)
  const options = {
    legend: {
      showForSingleSeries: true,
      position: 'top',
      onItemClick: {
        toggleDataSeries: false
      },
    },
    markers: {
      size: 2,
      colors: series?.color,
      strokeColors: series?.color
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      }
    },
    stroke: {
      width: [3],
    },
    grid: {
      show: true,
      borderColor: "#E9EFF5",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      axisBorder: {
        show: true,
        color: '#87939D',
        offsetX: 0,
        offsetY: 1
      },
      axisTicks: {
        show: true,
        color: '#78909C',
        height: 4,
        offsetX: 0,
        offsetY: 0
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
            colors: '#87939D',
            fontSize: '12px',
            fontFamily: 'Inter, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        offsetY: -3,
      }
    },
    tooltip: {
      enabled: !isEmpty,
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          return value?.toFixed(2);
        }
      }
    }
  }

  
  const ChartComponent = () => {
    return <Chart
      options={{
        ...options,  
          yaxis: yAxisSetting
      }}
      series={[series]}
      type="line"
      height="100%"
      width="100%"
    />
  }

  const Loader = () => {
    return <DigitalTwinChartLoader>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <PulseLoader
          color={"#B2AFC3"}
          size={30}
          speedMultiplier={1}
        />
      </Box>
      <Typography
        style={{
          color: "#B2AFC3",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        Loading
      </Typography>
    </DigitalTwinChartLoader>
  }

  const NoDataMessage = () => {
    return <div
        style={{
          position: "absolute",
          top: "40%",
          left: "40%",
          zIndex: 2
        }}
      >
        <div
          style={{
            backgroundColor: "#ececec",
            padding: "10px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="textSubtitle">
            No Data Found
          </Typography>
        </div>
      </div>
  }

  return (
    <>
      {state === CODE.LOADING ? <Loader/> : <ChartComponent/>}
      {isEmpty && <NoDataMessage/>}
    </>
  ) 
}

export default AnalyticsBasicLine