import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/MoonLoader";
import { KPICardContainer, KPIDate, KPILabel, KPILoaderBox, KPIValue } from "./StyledComponent"
import CODE from "../../../Static/Constants/StatusCodes";
import { getMetricInfo } from "../../../Api/ChargingAnalytics";
import moment from "moment";

function KPICard({ metricName, startDate, endDate, decimalPlaces }) {
  const [metricData, setMetricData] = useState({
    data: {},
    responseStatus: { code: CODE.LOADING, msg: "" },
  })
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const Loader = (props) => {
    return <KPILoaderBox>
      <PulseLoader
        color={"#B2AFC3"}
        size={30}
        speedMultiplier={1}
      />
    </KPILoaderBox>
  }

  const fetchMetricData = async () => {
    setMetricData({
      data: {}, 
      responseStatus: {
        code: CODE.LOADING,
        msg: "",
      }
    })
    const res = await getMetricInfo(metricName, startDate, endDate, selectedFilters, defaultFilterValues)
    const { responseStatus, response } = res;
    if (responseStatus.code !== CODE.SUCCESS) {
      setMetricData({
        data: {}, 
        responseStatus: {
          code: responseStatus.code,
          msg: responseStatus.msg,
        }
      })
      return
    }

    setMetricData({
      data: response.data, 
      responseStatus: {
        code: responseStatus.code,
        msg: responseStatus.msg,
      }
    })
  }

  useEffect(() => {
    if (Object.keys(selectedFilters).length) {
      fetchMetricData(metricName, startDate, endDate)
    }
  }, [startDate, endDate, selectedFilters])

  return (
    <KPICardContainer>
      {metricData.responseStatus.code === CODE.LOADING 
        ? <Loader/>
        : <>
            <KPILabel>
                {metricData.data?.label}
            </KPILabel>
            <KPIValue>
                {Number.isFinite(metricData.data?.value) ? metricData.data?.value?.toFixed(decimalPlaces) : "- "} {metricData.data.unit}
            </KPIValue>
            <KPIDate>
              From {moment(startDate).format('MMMM YYYY')}
            </KPIDate>
          </>
      }
    </KPICardContainer>
  )
}

KPICard.defaultProps = {
  decimalPlaces: 0,
}

export default KPICard
