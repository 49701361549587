import { Box, IconButton, Select, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomIcon from "../../../../../Components/CustomIcon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/MoonLoader";
import { convertAndGetTimeStampInRequiredFormat } from "../../../../../Helper/DatePicker/DateConverter";
import Events from "../../../../../Analytics/Events";
import { getTruncatedEntityName } from "../../../../../Helper/DigitalTwin/DigitalTwinHelper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.isExpanded ? (
        <KeyboardArrowUpIcon
          sx={{
            fontSize: "18px",
            marginRight: "6px",
            color: "#444B51",
          }}
        />
      ) : (
        <KeyboardArrowDownIcon
          sx={{
            fontSize: "18px",
            marginRight: "6px",
            color: "#444B51",
          }}
        />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  margin: 0,
  padding: 0,
  minHeight: 0,
  flexDirection: "row",
  alignItems: "center",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  margin: 0,
  fontSize: "12px",
  lineHeight: "16px",
  borderTop: "1px solid rgba(193, 193, 193, 0.4)",
  borderRight: 0,
}));

const AlertAccordion = (props) => {
  const {
    batteryID,
    name,
    alertData,
    alertColor,
    onClickAlert,
    selectedAlertID,
    ...other
  } = props;
  const [expanded, setExpanded] = React.useState(decryptTheParams()?.expandedAccordionName);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleAlertClick = (entityID, dbTime, alertStateID, level) => {
    const uniqueAlertID = entityID + "#" + dbTime + "#" + alertStateID;
    onClickAlert([entityID], uniqueAlertID, level, dbTime, expanded);
  };

  return (
    <Accordion
      style={{
        margin: 0,
        padding: 0,
      }}
      expanded={expanded === name}
      onChange={handleChange(name)}
    >
      <AccordionSummary
        isExpanded={expanded === name}
        sx={{
          margin: 0,
          "&:hover": {
            background: "#EAEEFF",
          },
        }}
        aria-controls={`${name}d-content`}
        id={`${name}d-header`}
      >
        <Box
          style={{
            width: "4px",
            background: `${alertColor}`,
            borderRadius: "0 4px 4px 0",
            marginTop: "1px",
            marginBottom: "1px",
          }}
        />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            margin: 0,
            paddingLeft: "5px",
            paddingTop: "18px",
            paddingBottom: "18px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#8E8E8E",
            }}
          >
            ({alertData?.alertState?.length})
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#444B51",
              marginLeft: "7px",
            }}
          >
            {alertData?.alertMeta?.name}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {alertData.alertState.map((item, alertIndex) => {
          let entityName, entityNameToDisplay, splittedEntitiesNames;
          let alertStateColor = "#35BEE3";
          let systemID;
          switch (item.severity.toLowerCase()) {
            case "high":
              alertStateColor = "#F43F40";
              break;
            case "medium":
              alertStateColor = "#FFDD86";
              break;
          }

          switch (item?.level) {
            case "battery":
              entityName = "battery"; // using string instead of batteryID since we are using this text to select battery system in hierarchy
              entityNameToDisplay = "Battery System";
              systemID = "battery"
              break;
            case "pack":
              splittedEntitiesNames = item?.packID?.toLowerCase()?.split("_");
              entityName = item?.packID?.toLowerCase();
              entityNameToDisplay = getTruncatedEntityName(splittedEntitiesNames[0]?.toUpperCase(), 10, 4, 5)
              if (splittedEntitiesNames.length > 1) {
                entityNameToDisplay = splittedEntitiesNames[1]?.toUpperCase()
              }
              systemID = item?.systemID;
              break;
            case "module":
              splittedEntitiesNames = item?.moduleID?.toLowerCase()?.split("_");
              entityName = item?.moduleID?.toLowerCase();
              entityNameToDisplay = getTruncatedEntityName(splittedEntitiesNames[0]?.toUpperCase(), 10, 4, 5)
              if (splittedEntitiesNames.length > 1) {
                entityNameToDisplay = `${splittedEntitiesNames[1]?.toUpperCase()} / ${splittedEntitiesNames[2]?.toUpperCase()}`;
              }
              systemID = entityName;
              break;
            case "cell":
              splittedEntitiesNames = item?.cellID?.toLowerCase()?.split("_");
              entityName = item?.cellID?.toLowerCase();
              entityNameToDisplay = getTruncatedEntityName(splittedEntitiesNames[0]?.toUpperCase(), 10, 4, 5)
              if (splittedEntitiesNames.length > 1) {
                entityNameToDisplay = `${splittedEntitiesNames[1]?.toUpperCase()} / ${splittedEntitiesNames[2]?.toUpperCase()} / ${splittedEntitiesNames[3]?.toUpperCase()}`;
              }
              systemID = entityName;
              break;
          }

          if (expanded === name && alertIndex === 0 && decryptTheParams()?.redirectedFromAlertPage === 'true') {
            handleAlertClick(
              systemID,
              item?.customerDBTime,
              item?.alertStateID,
              item?.level
            )
          }

          return (
            <Box
              sx={{
                display: "flex",
                paddingTop: "11px",
                paddingBottom: "11px",
                paddingRight: "11px",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(193, 193, 193, 0.4)",
                paddingLeft: "27px",
                cursor: "pointer",
                background:
                    systemID +
                    "#" +
                    item?.customerDBTime +
                    "#" +
                    item?.alertStateID ===
                    selectedAlertID
                    ? "#EAEEFF"
                    : "transparent",
                "&:hover": {
                  background: "#EAEEFF",
                },
              }}
              onClick={() =>
                handleAlertClick(
                  systemID,
                  item?.customerDBTime,
                  item?.alertStateID,
                  item?.level
                )
              }
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CustomIcon
                  name="dot"
                  width={5}
                  height={6}
                  viewBox={"0 -1 6 7"}
                  fill={alertStateColor}
                />
                <Typography
                  style={{
                    marginLeft: "7px",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    color: "#444B51",
                  }}
                >
                  {entityNameToDisplay}
                </Typography>
              </Box>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#87939D",
                }}
              >
                {convertAndGetTimeStampInRequiredFormat(
                  item.customerDBTime,
                  "DD MMM'YY, hh:mm A"
                )}
              </Typography>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const AlertTab = (props) => {
  const {
    alertsFilters,
    alertsSorts,
    alertList,
    getAlerts,
    batteryID,
    dateFilters,
    onClickAlert,
    isAlertFetching,
    selectedAlertID,
    ...other
  } = props;
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const queryParams = decryptTheParams();

  const screenHeight = window.innerHeight;

  const getSectionTitle = () => {
    let title;
    switch (queryParams.alertFilterValue) {
      case "1":
        title = "Medium Severity Alerts";
        break;
      case "2":
        title = "High Severity Alerts";
        break;
      case "3":
        title = "Critical Severity Alerts";
        break;

      default:
        title = "Active Alerts";
        break;
    }

    return title;
  };

  const handleSorting = (sortBy) => {
    let sortByColumn, order;
    switch (sortBy.value) {
      case "latest_pinged":
        Events(
          `Vital Section alert tab sorting changed to Last Pinged in Desc order`
        );
        sortByColumn = "customerDBTime";
        order = "desc";
        break;
      case "high_to_low":
      case "low_to_high":
        sortByColumn = "severityLevelID";
        order = sortBy.value === "high_to_low" ? "desc" : "asc";
        Events(
          `Vital Section alert tab sorting changed to Severity ${sortBy.value}`
        );
        break;
    }

    const params = {
      ...queryParams,
      alertSortBy: sortByColumn,
      alertSortOrder: order,
      alertSortValue: sortBy.value,
    };
    getAlerts({ sortBy: sortByColumn, order: order });
    encryptTheParams(params, navigate, true);
  };

  const handleFilters = (filterBy) => {
    Events(`Vital Section alert tab filter changed to ${filterBy.value}`);
    const status = filterBy.value === "active" ? "active" : "";
    const severity = filterBy.value === "active" ? "" : filterBy?.value || "";
    const params = {
      ...queryParams,
      alertFilterStatusValue: status,
      alertFilterSeverityValue: severity,
      alertFilterValue: filterBy?.value,
    };

    const filter = {
      status: status,
      severity: severity,
    };

    getAlerts(filter);
    encryptTheParams(params, navigate, true);
  };

  // useEffect(() => {
  //   const severity = queryParams?.alertFilterSeverityValue;
  //   const status = queryParams?.alertFilterStatusValue;
  //   const sortBy = queryParams?.alertSortBy;
  //   const sortOrder = queryParams?.alertSortOrder;

  //   getAlerts({
  //     sortBy: sortBy,
  //     order: sortOrder,
  //     severity: severity,
  //     status: status,
  //   });
  // }, []);

  return (
    <Box
      style={{
        ...props.style,
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "16px",
          paddingRight: "7px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "24px",
          }}
        >
          {`${getSectionTitle()}`}
        </Typography>
        {/* <Box>
          <IconButton
            style={{
              padding: 0,
            }}
            disableRipple
            children={<CustomIcon name="alert-filter" fill={"#768CFF"} />}
            onClick={() => {
              setIsFilterOpen(true);
            }}
          />
          <Select
            open={isFilterOpen}
            style={{
              opacity: 0,
              width: 0,
              height: 15,
              zIndex: -1,
            }}
            value={queryParams?.alertFilterValue || "active"}
            onClose={() => {
              setIsFilterOpen(false);
            }}
            onChange={(e) => handleFilters(e.target)}
          >
            {alertsFilters?.map((item) => (
              <MenuItem sx={{ fontSize: "small" }} value={item.value}>
                {item.key}
              </MenuItem>
            ))}
          </Select>

          <IconButton
            disableRipple
            style={{
              padding: 0,
              marginLeft: "2px",
            }}
            children={
              <CustomIcon
                name="sort"
                width={30}
                height={30}
                viewBox={"0 0 20 20"}
              />
            }
            onClick={() => {
              setIsSortingOpen(true);
            }}
          />
          <Select
            open={isSortingOpen}
            style={{
              opacity: 0,
              width: 0,
              height: 15,
              zIndex: -1,
            }}
            value={queryParams?.alertSortValue}
            onClose={() => {
              setIsSortingOpen(false);
            }}
            onChange={(e) => handleSorting(e.target)}
          >
            {alertsSorts?.map((item) => (
              <MenuItem sx={{ fontSize: "small" }} value={item.value}>
                {item.key}
              </MenuItem>
            ))}
          </Select>
        </Box> */}
      </Box>
      <Box
        style={{
          marginTop: "5px",
          height: screenHeight <= 700 ? "45vh" : "65vh",
          overflowY: "auto",
          width: "100%",
          paddingBottom: "100px",
        }}
      >
        {isAlertFetching ? (
          <Box
            style={{
              marginTop: "50px",
              textAlign: "center",
            }}
          >
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <PulseLoader color={"#B2AFC3"} size={30} speedMultiplier={1} />
            </Box>
            <Typography
              style={{ color: "#B2AFC3", marginTop: "10px", fontSize: "14px" }}
            >
              Loading
            </Typography>
          </Box>
        ) : alertList?.length ? (
          alertList.map((alert, index) => {
            const severity = alert?.alertMeta?.severity?.toLowerCase();
            const alertMetaName =  alert?.alertMeta?.name?.toLowerCase()
            const formattedName = alertMetaName.toLowerCase().replace(/ /g, "-");
            const accordionName = `alertGroup${formattedName}`

            // WorkAround: AlertColor is not being assigned properly inside the AlertAccordion component, so sending it through props
            var alertColor = "#35BEE3";
            switch (severity) {
              case "high":
                alertColor = "#F43F40";
                break;
              case "medium":
                alertColor = "#FFDD86";
                break;
            }

            return (
              <AlertAccordion
                name={accordionName}
                alertData={alert}
                alertColor={alertColor}
                batteryID={batteryID}
                onClickAlert={onClickAlert}
                selectedAlertID={selectedAlertID}
              />
            );
          })
        ) : (
          <Typography
            style={{
              textAlign: "center",
              color: "#B2AFC3",
              marginTop: "50px",
              fontSize: "14px",
            }}
          >
            No Data Available
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AlertTab;
