import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material"
import GenericMultipleSelect from "./GenericMultipleSelect"
import { FilterContainer, ResetFilterBox } from "../../Components/CustomBox"
import { areArraysEqual } from "../../Helper/Operations/ArrayOps"
import { SetFilterDefaultValues, SetFilterValues } from "../../Actions/Filters"
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt"
import { extractArrayValuesForFilters } from "../../Helper/Operations/GetFilterKeyValue";
import CODE from "../../Static/Constants/StatusCodes";
import { getFilterData } from "../../Api/Api";

export default function GenericFilterContainer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filtersConfig = props.filtersConfig || []
  const [resetButton, setResetButton] = useState(false);
  const [filtersOptions, setFilterOptions] = useState({});
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);

  useEffect(() => {
    fetchFiltersData();
  }, [])

  useEffect(() => {
    showResetButton()
  }, [selectedFilters])

  const fetchFiltersData = async () => {
    let filtersData = {};
    
    if (!filtersConfig.length) {
      return filtersData
    }

    await Promise.all(
      filtersConfig.map(async (filter) => {
        if (filtersData[filter.key]) {
          return;
        }

        if (filter.type === 'static') {
          filtersData[filter.key] = filter.staticData;
          return;
        }

        const res = await getFilterData(filter);
        if (res && res?.responseStatus?.code === CODE.SUCCESS) {
          filtersData[filter.key] = res.response.response.filterData;
        }
      })
    );
    setFilterOptions(filtersData)
    
    const defaultFilters = extractArrayValuesForFilters(filtersData)
    let selectedFiltersValues = defaultFilters
    const queryParams = decryptTheParams()
    if (queryParams.filters) {
      selectedFiltersValues = JSON.parse(queryParams.filters)
    }
    dispatch(SetFilterValues(selectedFiltersValues))
    dispatch(SetFilterDefaultValues(defaultFilters))
    return filtersData;
  };

  const handleFilterValueChange = (key, label, selectedValue) => {
    const newSelectedValues = {...selectedFilters}
    const queryParams = decryptTheParams();
    
    newSelectedValues[key] = selectedValue
    dispatch(SetFilterValues(newSelectedValues))
    props.onSelectFilter(key, label, selectedValue)
    
    const temp = {
      ...queryParams,
      filters: JSON.stringify(newSelectedValues)
    };
    encryptTheParams(temp, navigate);
  }

  const resetFilters = () => {
    dispatch(SetFilterValues(defaultFilterValues))
    setResetButton(false);
    updateQueryParams(navigate, {
      filters: ""
    })
    props.onResetFilters()
  }

  const showResetButton = () => {
    const allAvailableFilters = props.filtersConfig || []
    for (let filterConfig of allAvailableFilters) {
      if (!areArraysEqual(defaultFilterValues?.[filterConfig.key] || [], selectedFilters?.[filterConfig.key] || [])) {
        setResetButton(true);
        return
      }
    }

    setResetButton(false);
  }

  const getMultiSelectDropdownFilter = (data) => {
    return (
      <GenericMultipleSelect
        label={data?.label}
        options={filtersOptions[data.key]}
        maxWidth={"100px"}
        optionValue={selectedFilters?.[data.key] || []}
        handleSetFilter={(label, value) => {
          handleFilterValueChange(data.key, label, value)
        }}
        id="overflow-text-3"
      />
    )
  }

  const renderFilter = (filterData) => {
    switch (filterData.displayType) {
      case "multiSelectDropdown":
        return getMultiSelectDropdownFilter(filterData)
    }
  }

  return (
    <FilterContainer>
      {resetButton && (
        <ResetFilterBox
          onClick={resetFilters}
        >
          Reset
        </ResetFilterBox>
      )}
      {filtersConfig.map((filter, index) => {
        if (filter.tabs) {
          return filter.tabs.includes(props.activeTabValue) ? (
            <Box key={index}>{renderFilter(filter)}</Box>
          ) : null
        }

        return <Box key={index}>{renderFilter(filter)}</Box>
      })}
    </FilterContainer>
  )
}
