import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/ChargingAnalytics";
import CODE from "../../../Static/Constants/StatusCodes";
import { getXAxisDateString } from "../../../Helper/Charts/ChartHelper";
import AnalyticsBasicLine from "../../../Charts/Line/AnalyticsBasicLine";

const defaultChartState = {
  series: [],
  statusCode: CODE.LOADING
}

const yAxisSetting = {
  title: {
    text: 'Energy Efficiency (kWh/Km)',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
      fontWeight: 500,
    },
  },
  showAlways: true,
  decimalsInFloat: 1,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: -2,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '12px',
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        fontWeight: 400,
    },
  },
  tickAmount: 5,
  min: 0,
  max: 0.5
}

const EnergyEfficiencyChart = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText }) => {
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchData();
    }
  }, [
    granularity,
    filters
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["efficiency"], startDate, endDate, granularity.granularity, filters, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    const series =  {
      name: "Energy Efficiency (kWh/Km)",
      type: 'line',
      color: "#C8607B",
      data: response.data?.map(data => {
          const value = data.metricsArray.find(item => item.type == 'efficiency')?.value
          const {x, dateRangeString } = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity)
          return {
              x,
              y: value,
              dateRangeString
          }
      })
    }

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }
    const data = [
      ...chartState.series.data.map(data => ({
        "Date Range": data.dateRangeString,
        "Energy Efficiency (kWh/Km)": data.y?.toFixed(2),
      })),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }
  
  return (
    <CardWithHeader
      id='energyEfficiencyChart'
      title="Energy Efficiency"
      subtitle={`Energy consumed vs Distance covered across fleet ${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="energyEfficiency" style={{ width: 22, height: 22 }}/>}
      pageLabel="Charging Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"EnergyEfficiency"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Charging Analytics: Hover over Energy Efficiency chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"358px", position: "relative", paddingLeft: "16px", paddingRight: "16px" }}
    >
      <AnalyticsBasicLine
        state={chartState.statusCode}
        series={chartState.series}
        yAxisSetting={yAxisSetting}
      />
    </CardWithHeader>
  )
}

export default EnergyEfficiencyChart;
