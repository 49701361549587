import copy from "copy-to-clipboard";
import { encryptQueryParams, decryptQueryParams } from "query-string-hash";

const decryptTheParams = () => {
  let query = window.location.search.slice(1);
  const queryParams = decryptQueryParams(query);
  return queryParams;
};

const encryptTheParams = (temp, navigate, specificBattery, path) => {
  const que = new URLSearchParams(temp).toString();
  const hash = encryptQueryParams(que);
  if (specificBattery) {
    if (temp && temp.navigateFromTab === true) {
      window.open(`/specificBattery?${hash}`, "_blank", "noreferrer");
    } else {
      navigate(`/specificBattery?${hash}`);
    }
  } else if (path) {
    navigate(`${path}?${hash}`);
  } else {
    navigate(`${window.location.pathname}?${hash}`);
  }
};

const encryptTheParamsForOldDigitalTwinPage = (temp, navigate, specificBattery, path) => {  // Temporary Changes to access old Digital Twin Page
  const que = new URLSearchParams(temp).toString();
  const hash = encryptQueryParams(que);
  if (specificBattery) {
    if (temp && temp.navigateFromTab === true) {
      window.open(`/specificBatteryOld?${hash}`, "_blank", "noreferrer");
    } else {
      navigate(`/specificBatteryOld?${hash}`);
    }
  } else if (path) {
    navigate(`${path}?${hash}`);
  } else {
    navigate(`${window.location.pathname}?${hash}`);
  }
};

const setTheFilterValues = (filterName, value, navigate) => {
  let temp;
  const queryParams = decryptTheParams();

  if (value !== "") {
    temp = {
      ...queryParams,
      [filterName]: value,
    };
  } else {
    temp = Object.fromEntries(
      Object.entries(queryParams).filter(([key, val]) => key !== filterName)
    );
  }
  delete temp["id"];
  if (Object.keys(temp).length === 0) {
    navigate(`${window.location.pathname}`);
  } else {
    encryptTheParams(temp, navigate);
  }
};

const resetTheFilterValues = (arr, navigate) => {
  const queryParams = decryptTheParams();
  arr.forEach((filterName) => {
    for (let key in queryParams) {
      if (key === filterName || key === "endDate" || key === "startDate") {
        delete queryParams[key];
      }
    }
  });
  encryptTheParams(queryParams, navigate);
};

const updateQueryParams = (navigate, params, isDigitalTwinPage=false) => {
  const queryParams = decryptTheParams()
  let temp = {
    ...queryParams,
    ...params
  };
  encryptTheParams(temp, navigate, isDigitalTwinPage);
}

const copyCurrentURLToClipboard = () => {
  const currentURL = window.location.href;
  copy(currentURL);
};

export {
  updateQueryParams,
  decryptTheParams,
  encryptTheParams,
  setTheFilterValues,
  resetTheFilterValues,
  copyCurrentURLToClipboard,
  encryptTheParamsForOldDigitalTwinPage  // Temporary Changes to access old Digital Twin Page
};
