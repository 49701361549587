const findEntityDisplayName = (data, entityName) => {
  if (!data) return null;

  if (data.name.toLowerCase() === entityName.toLowerCase()) {
    return data.displayName;
  }

  if (data.content && Array.isArray(data.content)) {
    for (const item of data.content) {
      const result = findEntityDisplayName(item, entityName);
      if (result) return result;
    }
  }

  return null;
}

const getCustomerEntityName = (hierarchy, entityName) => {
  const batteryStructure = hierarchy?.batteryStructure
  if (!batteryStructure || !entityName) {
    return null
  }
  for (let pack of batteryStructure) {
    const result = findEntityDisplayName(pack, entityName);
    if (result) return result;
  }
  return null;
};

const findSystemID = (data, entityName) => {
  if (!data) return null;

  if (data.name.toLowerCase() === entityName.toLowerCase()) {
    return data?.systemID;
  }

  if (data.content && Array.isArray(data.content)) {
    for (const item of data.content) {
      const result = findSystemID(item, entityName);
      if (result) return result;
    }
  }

  return null;
}

const getSystemIDForEntity = (hierarchy, entityName) => {
  const batteryStructure = hierarchy?.batteryStructure
  if (!batteryStructure || !entityName) {
    return null
  }
  for (let pack of batteryStructure) {
    const result = findSystemID(pack, entityName);
    if (result) return result;
  }
  return null;
};

const getTruncatedEntityName = (entityName, characterLimit, leftSideLength=5, rightSideLength=12) => {
  return entityName?.length > characterLimit
        ? entityName.substring(0, leftSideLength) +
        "..." +
        entityName.substring(entityName.length - rightSideLength)
        : entityName;
}

const sortBySequence = (arrayToSort, sequenceObject, sequenceKey) => {
  const sequence = sequenceObject[sequenceKey]
  return arrayToSort.sort((a, b) => (sequence.indexOf(a) - sequence.indexOf(b)));
}

export {
  getCustomerEntityName,
  getTruncatedEntityName,
  getSystemIDForEntity,
  sortBySequence
}
