import React, { useState } from "react"
import { Table, Pagination } from "antd"
import ReactDragListView from "react-drag-listview"
import { ConfigProvider } from "antd"
import { useDispatch } from "react-redux"
import { ThemeProperties } from "../../Theme/ThemeProperties"
import { PaginationContainer } from "../../Components/CustomBox"
import { SetDialogTablePageNumber, SetDialogTableRowsPerPage, SetTableColumns, SetTablePageNumber, SetTableRowsPerPage } from "../../Actions/Table"
import Loader from "../../Helper/Loaders/Loader"
import { handleScroll } from "./AntDesign/TableFunctions"
import { SetDialogTableSortColumn, SetDialogTableSortOrder, SetTableSortColumn, SetTableSortOrder } from "../../Actions/Filters"
import { useNavigate } from "react-router-dom"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt"


const GenericTable = ({
  totalRecords,
  tableData,
  columns,
  type,
  spinning,
  selectedRowKeys,
  numberOfFixedColumns,
  isRowSelectable,
  emptyTextBox,
  generateRowId,
  tableStyle,
  onClickSelectAll,
  onRowSelect,
  rowsPerPage,
  pageNumber,
  renderCell,
  onPageChange,
  onShowSizeChange,
  getCheckboxProps,
  pageSizeOptions,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableColumns, setColumns] = useState(columns)
  const rowSelectionConfig = {
    selectedRowKeys: selectedRowKeys,
    onSelectAll: onClickSelectAll,
    onSelect: onRowSelect,
    renderCell: renderCell,
    getCheckboxProps: getCheckboxProps
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = isRowSelectable
        ? fromIndex > numberOfFixedColumns && toIndex > numberOfFixedColumns
        : fromIndex > numberOfFixedColumns - 1 && toIndex > numberOfFixedColumns - 1;

      if (dragIndex) {
        const newColumns = [...tableColumns];
        const item = isRowSelectable
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        
        isRowSelectable
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setColumns(newColumns);
      }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      const order = sorter.order === "ascend" ? "asc" : "desc";
      const sortColumn = sorter.column.dbColumnKey
      if (type === 'primaryTable') {
        dispatch(SetTableSortColumn(sortColumn));
        dispatch(SetTableSortOrder(order));
        updateQueryParams(navigate, {
          sortColumn: sortColumn,
          sortOrder: order
        })
        return
      } 
      dispatch(SetDialogTableSortColumn(sortColumn));
      dispatch(SetDialogTableSortOrder(order));
      updateQueryParams(navigate, {
        dialogTableSortColumn: sortColumn,
        dialogTableSortOrder: order
      })
    }
  };

  const handlePageChange = (page, pageSize) => {
    if (type === 'primaryTable') {
      dispatch(SetTablePageNumber(page));
    } else {
      dispatch(SetDialogTablePageNumber(page));
    }
    onPageChange(page, pageSize)
  };
  
  const handleRowsPerPageChange = (current, pageSize) => {
    if (type === 'primaryTable') {
      dispatch(SetTablePageNumber(1));
      dispatch(SetTableRowsPerPage(pageSize));
    } else {
      dispatch(SetDialogTablePageNumber(1));
      dispatch(SetDialogTableRowsPerPage(pageSize));
    }
    onShowSizeChange(current, pageSize)
  };
  if (columns.length < 1) {
    return <></>
  }
  
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ThemeProperties.purple
        }
      }}
    >
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          rowKey={generateRowId}
          rowSelection={isRowSelectable && rowSelectionConfig}
          columns={tableColumns}
          dataSource={tableData?.data || []}
          className={
            isRowSelectable
              ? "LandingPageTable Selection"
              : "LandingPageTable NoSelection"
          }
          pagination={false}
          onChange={handleTableChange}
          style={tableStyle}
          bordered
          size="middle"
          scroll={handleScroll([...columns])}
          locale={{emptyText: emptyTextBox}}
          loading={{ 
            spinning: spinning,
            indicator: (
              <>
                <div style={{ height: "130px" }}></div>
                <Loader />
              </>
            ),
          }}
        />
        <PaginationContainer>
          <Pagination
            showSizeChanger
            onShowSizeChange={handleRowsPerPageChange}
            pageSize={rowsPerPage}
            pageSizeOptions={pageSizeOptions}
            current={pageNumber}
            onChange={handlePageChange}
            total={totalRecords}
            showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
          />
        </PaginationContainer>
      </ReactDragListView.DragColumn>
    </ConfigProvider>
  )
}

GenericTable.defaultProps = {
  tableData: {
    data: [],
    responseStatus: {}
  },
  totalRecords: 0,
  selectedRowKeys: [],
  numberOfFixedColumns: 1,
  type: "primaryTable",
  tableStyle: { height: "575px" },
  pageSizeOptions: [10, 20, 30],
  emptyTextBox: <></>,
  spinning: false,
  onRowSelect: () => {},
  onClickSelectAll: () => {},
  getCheckboxProps: () => {},
  generateRowId: (record) => record.id,
  onPageChange: () => {},
  onShowSizeChange: () => {},
  renderCell: undefined
}

export default GenericTable