import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Checkbox from "@mui/material/Checkbox";
import { Checkbox, ConfigProvider } from "antd";

import ListItemIcon from "@mui/material/ListItemIcon";
import { BasicSelect } from "../../Components/CustomSelect";
import { Button, Typography } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { MoonLoader } from "react-spinners";
import { FilterLoaderBox } from "../../Components/CustomBox";

function getArrayOfValues(arr) {
  let temp = [];
  arr.map((item) => {
    temp.push(item.id);
  });
  return temp;
}

export default function GenericMultipleSelect(props) {
  const options = props.options;
  const [selected, setSelected] = useState(props.optionValue);
  const isAllSelected =
    options?.length > 0 && selected?.length === options?.length;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "All") {
      let newValue =
        selected?.length === options?.length ? [] : getArrayOfValues(options);
      setSelected(newValue);
      // props.handleSetFilter(props.label,newValue)
      return;
    }
    setSelected(value);
    // props.handleSetFilter(props.label,value)
  };

  const getRenderValue = (selected) => {
    if (selected?.length === options?.length) return "All";

    const selectedOption = options?.find((option) => option.id === selected[0]);

    if (selected?.length === 1) {
      return selectedOption ? selectedOption.value : "";
    }

    if (selected?.length > 1) {
      return selectedOption
        ? `${selectedOption.value} +${selected?.length - 1}`
        : "";
    }
  };

  useEffect(() => {
    setSelected(props.optionValue);
  }, [props.optionValue]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ThemeProperties.purple,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid rgb(189,189,189)",
            borderRadius: "4px",
            width: "120px",
            mr: 2,
            mt: 1,
            mb: 1,
            height: "35px",
            bgcolor:
              selected?.length === options?.length
                ? "white"
                : selected?.length > 0
                  ? ThemeProperties.purpleSelected
                  : "white",
            //   maxWidth: props.maxWidth
          }}
        >
          <FormControl sx={{ m: 0, minWidth: 100, maxWidth: 100 }} size="small">
            {options?.length 
              ? <InputLabel style={{maxWidth: "none"}} id="demo-select-small">
                <Typography
                  sx={{
                    fontSize: 13,
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    mt: selected?.length === 0 ? -0.2 : 0.5,
                    pr: 1,
                    pl: 1,
                    bgcolor:
                      selected?.length === options?.length
                        ? "white"
                        : selected?.length > 0
                          ? ThemeProperties.backgroundPurple
                          : "white",
                  }}
                >
                  {props.label}
                </Typography> 
              </InputLabel>
              : <FilterLoaderBox><MoonLoader size={20}/></FilterLoaderBox>
            }
            {options?.length && <BasicSelect
              labelId="mutiple-select-label"
              multiple
              value={selected}
              onChange={handleChange}
              renderValue={(selected) => {
                return (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                    id={props.id}
                  >
                    {getRenderValue(selected)}
                  </Typography>
                );
              }}
              open={open}
              onClose={() => {
                handleClose();
                setSelected(props.optionValue);
              }}
              onOpen={handleOpen}
              // MenuProps={
              //   {
              //     // PaperProps: { sx: { maxHeight: 385 } },
              //     // autoFocus: false,
              //   }
              // }
              MenuProps={{
                style: { zIndex: 10001 },
              }}
              myColor={
                selected?.length === options?.length
                  ? "white"
                  : selected?.length > 0
                    ? ThemeProperties.purpleSelected
                    : "white"
              }
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    border: `1px solid ${
                      selected?.length === options?.length
                        ? "white"
                        : selected?.length > 0
                          ? ThemeProperties.purpleSelected
                          : "white"
                    }`,
                  },
                },
              }}
            >
              <MenuItem value="All">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selected?.length > 0 && selected?.length < options?.length
                    }
                  />
                </ListItemIcon>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, fontFamily: "Roboto" }}
                >
                  Select All
                </Typography>
              </MenuItem>

              {options?.map(({ id, value }) => (
                <MenuItem key={id} value={id}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        //   props.optionValuelength > 0 && props.optionValueindexOf(option) > -1
                        selected?.some((option) => option === id)
                      }
                    />
                  </ListItemIcon>
                  <Typography
                    sx={{ fontSize: 12, fontWeight: 500, fontFamily: "Roboto" }}
                  >
                    {value}
                  </Typography>
                </MenuItem>
              ))}

              <Box sx={{ margin: "8px 8px 0px 8px" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.handleSetFilter(props.label, selected);
                    handleClose();
                  }}
                  fullWidth
                  style={{ textTransform: "none" }}
                  disabled={selected?.length === 0}
                >
                  <Typography sx={{ fontSize: "12px", color: "white" }}>
                    Apply
                  </Typography>
                </Button>
              </Box>

              {/* </Box>     */}
            </BasicSelect>}
          </FormControl>
          <Box sx={{ ml: "-8px", mr: "1px" }}>
            <IconButton
              size="small"
              onClick={() => {
                setSelected(getArrayOfValues(options));
                props.handleSetFilter(props.label, getArrayOfValues(options));
              }}
            >
              <HighlightOffIcon sx={{ fontSize: "19px", mt: "-1px" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </ConfigProvider>
  );
}
