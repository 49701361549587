import React, { useState, useEffect } from "react"
import { IconButton, Typography, Popover, Box } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import LinkIcon from "@mui/icons-material/Link"
import DownloadIcon from "@mui/icons-material/Download"
import { SecondaryButton } from "../../Components/CustomButton"
import { ThemeProperties } from "../../Theme/ThemeProperties"
import Events from "../../Analytics/Events"
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV"
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV"
import CODE from "../../Static/Constants/StatusCodes"
import {
  CardHeaderBox,
  CardHeaderContainer,
  CardHeaderIconBox,
  CardMenuPopoverContainer,
  ChartHeadingText,
  ChartSubTitleText,
  FilterContainer,
  FlexBox,
} from "../../Components/CustomBox"
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt"
import { encryptQueryParams } from "query-string-hash"
import copy from "copy-to-clipboard"

function GenericHeader({
  id,
  pageLabel,
  heading,
  description,
  iconElement,
  showThreeDots,
  showCSVDownload,
  csvName,
  csvData,
  openNotification,
  closeNotification,
  generateCsvData
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const isPopoverOpen = Boolean(anchorEl)
  const popoverId = isPopoverOpen ? "simple-popover" : undefined

  useEffect(() => {
    handleCSVDownload()
  }, [csvData])

  const handleCSVDownload = () => {
    if (csvData?.responseStatus?.code === CODE.LOADING) {
      openNotification("bottomLeft", "Preparing to download ... ", 0, "info")
    } else if (csvData?.responseStatus?.code === CODE.SUCCESS) {
      closeNotification()
      const newBatteries = [...csvData.data]
      DownloadCSV(NameTheCSV(csvName, ""), newBatteries)

      setTimeout(() => {
        openNotification("bottomLeft", "Content Downloaded", 2, "success")
      }, 100)
    } else if (csvData?.responseStatus?.code === CODE.NODATA) {
      closeNotification()
      setTimeout(() => {
        openNotification("bottomLeft", "No data found", 2, "error")
      }, 100)
    }
  }

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const handleCopyLink = () => {
    Events(`${pageLabel}: Copied Link`)
    const queryParams = decryptTheParams()
    let temp;
    temp = {
      ...queryParams,
      id: id,
    };
    const que = new URLSearchParams(temp).toString();
    const encryptedParams = encryptQueryParams(que)
    const page = window.location.pathname;
    const appURL = window.location.origin;
    const link = `${appURL}${page}?${encryptedParams}`
    copy(link)
    handleMenuClose()
  }

  const handleCSVDownloadClick = () => {
    Events(`${pageLabel}:Clicked on Csv Download`)
    generateCsvData()
    handleMenuClose()
  }

  return (
    <CardHeaderContainer>
      <CardHeaderBox>
        <FlexBox>
          {iconElement && <CardHeaderIconBox>{iconElement}</CardHeaderIconBox>}
          <Box>
            <ChartHeadingText>{heading}</ChartHeadingText>
            {description && <ChartSubTitleText>{description}</ChartSubTitleText>}
          </Box>
        </FlexBox>
        {showThreeDots && (
          <IconButton
            aria-describedby={popoverId}
            onClick={handleMenuClick}
            sx={{
              backgroundColor: isPopoverOpen ? ThemeProperties.purple : "white",
              color: isPopoverOpen ? "white" : "#828A92"
            }}
          >
            <MoreVertIcon fontSize="small" sx={{ fontSize: "18px" }} />
          </IconButton>
        )}

        {/* Popover Menu */}
        <Popover
          id={popoverId}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              borderRadius: "12px",
              padding: "12px",
              maxWidth: "300px"
            }
          }}
        >
          <CardMenuPopoverContainer>
            <SecondaryButton onClick={handleCopyLink} sx={{ pr: 1 }}>
              <LinkIcon
                color="primary"
                fontSize="small"
                sx={{ fontSize: "18px", mr: 1 }}
              />
              <Typography variant="textSubtitlePurple">Copy Link</Typography>
            </SecondaryButton>

            {showCSVDownload && (
              <SecondaryButton onClick={handleCSVDownloadClick} sx={{ pr: 1 }}>
                <DownloadIcon
                  color="primary"
                  fontSize="small"
                  sx={{ fontSize: "18px", mr: 1 }}
                />
                <Typography variant="textSubtitlePurple">Download CSV</Typography>
              </SecondaryButton>
            )}
          </CardMenuPopoverContainer>
        </Popover>
      </CardHeaderBox>
    </CardHeaderContainer>
  )
}

GenericHeader.defaultProps = {
  heading: "",
  description: "",
  iconElement: null,
  pageLabel: "",
  showThreeDots: true,
  showCSVDownload: false,
  csvName: "CSV",
  csvData: {
    data: [],
    responseStatus: {
      code: null
    }
  }
}

export default GenericHeader
