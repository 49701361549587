import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import { CustomText, FlexBox, FlexCenterBox, FlexSpaceBetweenBox, FullWidthBox } from "../../../Components/CustomBox"

const PageTitle = styled(CustomText)({
  fontSize: "24px",
  fontWeight: "500",
})

const PageHeaderContainer = styled(FlexSpaceBetweenBox)({
  alignItems: "center",
})

const CalendarContainer = styled(FlexBox)({
  marginBottom: "11px",
  alignItems: "center",
})

const CalendarBox = styled(Box)({
  marginRight: "16px",
})

const KPIContainer = styled(FlexBox)({
  alignItems: "center",
  flexWrap: "wrap",
  gap: "30px",
})

const KPICardContainer = styled(Box)({
  maxHeight: "110px",
  width: "263px",
  padding: "24px",
  borderRadius: "14px",
  background: "linear-gradient(180deg, #FFFFFF 0%, #F2F0FF 100%)",
  boxShadow: "4px 4px 4px 0px rgba(209, 209, 209, 0.25)"
})

const KPILoaderBox = styled(FlexCenterBox)({
  height: "62px",
  alignItems: "center",
})

const KPILabel = styled(CustomText)({
  fontWeight: "300",
  fontSize: "18px",
  color: "#1C1C1C",
  lineHeight: "12px"
})

const KPIValue = styled(CustomText)({
  marginTop: "16px",
  fontWeight: "400",
  fontSize: "18px",
  color: "#1C1C1C",
  lineHeight: "12px"
})

const KPIDate = styled(CustomText)({
  marginTop: "8px",
  fontWeight: "400",
  fontSize: "12px",
  color: "#828A92",
  lineHeight: "14px"
})

const ChartSectionContainer = styled(Box)({
  marginTop: "32px",
})

const HeatMapLegendText = styled(CustomText)({
  fontSize: "12px", 
  fontWeight: "400"
})

const HeatMapLegendGradientBox = styled(Box)(({ gradientStyle }) => {
  return ({
    marginLeft: "8px", 
    marginRight: "8px", 
    width: "128px", 
    height: "16px",
    background: gradientStyle
  })
})

const HeatMapLegendContainer = styled(FullWidthBox)({
  marginTop: "5px",
  display: "flex", 
  justifyContent: "center",
  alignItems: "center",
})

export { 
  KPIDate,
  KPIValue,
  KPILabel,
  PageTitle,
  CalendarBox,
  HeatMapLegendText,
  HeatMapLegendContainer,
  HeatMapLegendGradientBox,
  PageHeaderContainer,
  CalendarContainer,
  KPIContainer,
  KPICardContainer,
  KPILoaderBox,
  ChartSectionContainer
}
