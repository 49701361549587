import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Events from "../../Analytics/Events";
import { encryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import Loader from "../../Helper/Loaders/Loader";
import "./chart.css";
import { FullHeightBox } from "../../Components/CustomBox";

const closestMultiple = (n, x) => {
  if (x > n) return x;
  let num = n;
  while (num % x !== 0) {
    num = num + 1;
  }
  return num;
}

const ApexScatterPlusLine = (props) => {
  const [mounted, setMounted] = useState(false);
  const [chartState, setChartState] = useState({
    series: [],
    options: {},
  });

  const getChartSeries = () => {
    return [
      {
        name: "SOH",
        type: "scatter",
        data: props.scatter,
      },
    ]
  }

  const getChartEventsSettings = (xMin, xMax, yMin, yMax) => {
    const xAxisSettings = (min, max, formatterPrecision = 1) => ({
      min: min,
      max: max,
      labels: {
        formatter: (value) => value?.toFixed(formatterPrecision),
      },
    });
  
    const yAxisSettings = (yAxis) => ({
      tickAmount: 4,
      min: yAxis?.[0]?.min || yMin,
      max: yAxis?.[0]?.max || yMax,
      title: {
        text: "SOH (%)",
        offsetY: 0,
        style: {
          color: ThemeProperties.black,
          fontSize: ThemeProperties.c_axis_title,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_axis_title_weight,
        },
      },
      labels: {
        show: true,
        rotateAlways: false,
        align: "center",
        minWidth: 50,
        maxWidth: 50,
        style: {
          colors: [ThemeProperties.c_axis_labels_color],
          fontSize: ThemeProperties.c_axis_labels,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_axis_labels_weight,
        },
        formatter: (value) => parseFloat(value)?.toFixed(1),
        offsetY: 0,
      },
    });
  
    const handleZoom = (event, chartContext) => {
      const MIN_ZOOM_RANGE = 1;
      const { xaxis, yaxis } = chartContext;
      let min = xaxis.min;
      let max = xaxis.max;
  
      if (max - min >= xMax) {
        return {
          xaxis: xAxisSettings(xMin, closestMultiple(xMax, 5), 0),
          yaxis: yAxisSettings(yaxis),
        };
      } else if (max - min < MIN_ZOOM_RANGE) {
        return {
          xaxis: xAxisSettings(min, min + MIN_ZOOM_RANGE, 1),
          yaxis: yAxisSettings(yaxis),
        };
      } else {
        return {
          xaxis: xAxisSettings(min, max, 1),
          yaxis: yAxisSettings(yaxis),
        };
      }
    };
  
    const handleResetZoom = () => ({
      xaxis: xAxisSettings(xMin, closestMultiple(xMax, 5), 0),
      yaxis: yAxisSettings(),
    });
  
    const handleDataPointSelection = (event, chartContext, config) => {
      Events("clicked Battery Id Fleet Battery Health");
  
      const dataPoint = config.w.globals.initialSeries[0].data[config.dataPointIndex];
      const queryParams = {
        batteryID: dataPoint.batteryID,
        deviceID: dataPoint.deviceID,
        navigateFromTab: true,
        tabValue: 'health',
      };
  
      encryptTheParams(queryParams, props.navigate, true);
    };
  
    return {
      beforeZoom: handleZoom,
      beforeResetZoom: handleResetZoom,
      dataPointSelection: handleDataPointSelection,
    };
  };
  

  const getCustomTooltip = ({ series, seriesIndex, dataPointIndex, w }) => {
    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    var ageInMonths = props.cycleAge ? data.secondaryX : data.x;
    var eqCycles = props.cycleAge ? data.x : data.secondaryX;
    return (
      '<div class="tooltip_container">' +
      `<span class="flex_center">` +
      `<span class="text_container">Serial Number<b>: ` +
      data.deviceID +
      "</b></span>" +
      "</span>" +
      '<hr class="horizontal_rule"></hr>' +

      `<span class="time_container">SOH (%) <b>: ` +
      data.y?.toFixed(1) +
      "</b></span>" +
      `<br></br>` +
      `<span class="time_container">Age (months) <b>: ` +
      ageInMonths?.toFixed(1) +
      "</b></span>" +
      `<br></br>` +

      `<span class="time_container">Eq. Cycle <b>: ` +
      eqCycles?.toFixed(1) +
      "</b></span>" +
      "</div>"
    );
  }

  const getXAxisSettingForChart = (xMin, xMax, xAxisTitle) => {
    return {
      type: "numeric",
      min: xMin,
      max: closestMultiple(xMax, 10),
      tickAmount: 10,
      title: {
        text: xAxisTitle,
        offsetX: 3.7,
        offsetY: 5,
        style: {
          color: ThemeProperties.xAxisLabelColor,
          fontSize: ThemeProperties.c_axis_title,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_axis_title_weight,
        },
      },
      labels: {
        show: true,
        rotateAlways: false,
        align: "center",
        style: {
          colors: ThemeProperties.xAxisLabelColor,
          fontSize: ThemeProperties.c_axis_labels,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_axis_labels_weight,
        },
        formatter: function (value) {
          return Math.round(value);
        },
        offsetY: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    }
  }

  const getYAxisSettingForChart = (yMin, yMax) => {
    return {
      min: yMin,
      max: yMax,
      tickAmount: 4,
      title: {
        text: "SOH (%)",
        offsetY: 0,
        style: {
          color: ThemeProperties.xAxisLabelColor,
          fontSize: ThemeProperties.c_axis_title,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_axis_title_weight,
        },
      },
      labels: {
        show: true,
        rotateAlways: false,
        align: "center",
        minWidth: 50,
        maxWidth: 50,
        style: {
          colors: [ThemeProperties.xAxisLabelColor],
          fontSize: ThemeProperties.c_axis_labels,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_axis_labels_weight,
        },
        formatter: function (value) {
          return value?.toFixed(0);
        },
        offsetY: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    }
  }

  const getChartOptions = () => {
    return {
      chart: {
        width: 500,
        zoom: {
          enabled: true,
          type: "xy",
          minZoom: 10,
        },
        resetIcon: {
          offsetX: -10,
          offsetY: 5,
          fillColor: "#fff",
          strokeColor: "#37474F",
        },
        animations: {
          enabled: false,
        },
        events: getChartEventsSettings(props.xMin, props.xMax, props.yMin, props.yMax),
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          left: 20,
        },
      },
      stroke: {
        show: false,
      },
      markers: {
        size: [3],
        strokeWidth: 1,
        hover: {
          size: 3,
          sizeOffset: 0.5,
        },
      },
      tooltip: {
        shared: false,
        intersect: false,
        followCursor: true,
        custom: getCustomTooltip,
      },
      colors: [ThemeProperties.purple, ThemeProperties.c_orange],
      xaxis: getXAxisSettingForChart(props.xMin, props.xMax, props.xAxisTitle),
      yaxis: getYAxisSettingForChart(props.yMin, props.yMax),
      legend: {
        show: false,
      },
    }
  }

  function mountTheChart() {
    setChartState({
      series: getChartSeries(),
      options: getChartOptions(),
    });
    setMounted(true);
  }

  useEffect(() => {
    setMounted(false);
    mountTheChart();
  }, [props.scatter, props.cycleAge]);

  return mounted 
    ? <Chart
      options={chartState.options}
      series={chartState.series}
      type="line"
      height="100%"
      width="100%"
      id={props.id}
    />
    : <FullHeightBox>
      <Loader />
    </FullHeightBox>
}

export default ApexScatterPlusLine;
