import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import BatteryInfoCardSection from "../BatteryInfoCardSection";
import HealthDegradationChartSection from "../HealthDegradationChartSection";

function BatteryOverview() {
  const searchID = useSelector((state) => state.SelectedSearchID.value);
  const searchType = useSelector((state) => state.SelectedSearchType.value);
  const searchConfig = useSelector(
    (state) => state.SelectedSearchConfig.value
  );
  const searchColumn = searchConfig.keys[searchType]
  const [batterySOH, setBatterySOH] = useState(null)

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <BatteryInfoCardSection searchID={searchID} searchType={searchType} searchColumn={searchColumn} setBatterySOH={setBatterySOH}/>
      </Grid>
      <Grid item xs={8}>
        <HealthDegradationChartSection 
          deviceID={searchID}
          searchID={searchID}
          searchColumn={searchColumn}
          soh={batterySOH}
          pageLabel="Battery Health"
          cardSubTitle="Curve representing the degrading SOH"
        />
      </Grid>
    </Grid>
  );
}

export default BatteryOverview;
