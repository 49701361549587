import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { PageTitle, PageHeaderContainer, CalendarContainer, CalendarBox } from "./Components/StyledComponent"
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker"
import GenericFilterContainer from "../../Features/Menu/GenericFilterContainer";
import {
  SetChargingAnalyticsStartDate,
  SetChargingAnalyticsEndDate
} from "../../Actions/ChargingAnalytics";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Cookies from "universal-cookie";
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { getDatePickerDate, getPreviousMonthDate } from "../../Helper/DatePicker/DateConverter";
import { rangePresets } from "../../Static/Data/ChargingAnalytics/ChargingAnalytics";

function PageHeaderSection({ pagesContent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const calendarStartDate = useSelector((state) => state.ChargingAnalyticsStartDate.value) || getPreviousMonthDate()
  const calendarEndDate = useSelector((state) => state.ChargingAnalyticsEndDate.value) || getDatePickerDate();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(cookies.get("timeZone"));

  const disabledDate = (current) => {
    return current && current < dayjs().tz().subtract(6, "month");
  };

  const onDateChange = (startDate, endDate) => {
    dispatch(SetChargingAnalyticsStartDate(startDate));
    dispatch(SetChargingAnalyticsEndDate(endDate));
    updateQueryParams(navigate, {'startDate': startDate, 'endDate': endDate});
  }

  return (
    <PageHeaderContainer>
      <PageTitle>
        {pagesContent.pageHeader}
      </PageTitle>
      <CalendarContainer>
        <CalendarBox className="charging-analytics-calendar">
          <AntDatePicker
            disabledDate={disabledDate}
            changeTheDate={onDateChange}
            date={[calendarStartDate, calendarEndDate]}
            allowClear={false}
            page={'chargingAnalytics'}
            rangePresets={rangePresets}
          />
        </CalendarBox>
        <GenericFilterContainer
          filtersConfig={pagesContent.filterConfigurations?.chargingAnalyticsPage?.filters}
          onSelectFilter={() => {}}
          onResetFilters={() => {}}
        />
      </CalendarContainer>
    </PageHeaderContainer>
  )
}

export default PageHeaderSection
